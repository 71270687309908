@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';

.assign-members-page {
  $breakpoint: 700px;
  background-color: lightmode.$background-primary;
  margin: 0;
  height: 100%;
  padding-bottom: spacing.$xxl;

  &__header {
    background-color: lightmode.$primary;
    padding: spacing.$l 0;
    z-index: 10;
  }

  &__forms {
    @media (min-width: 950px) {
      display: flex;
      align-items: flex-end;
      gap: spacing.$xxl;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: spacing.$l;

      @media (min-width: 950px) {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    color: lightmode.$on-primary;
    margin-bottom: spacing.$m;
  }

  &__club-select-text {
    color: lightmode.$on-primary;
    margin-bottom: spacing.$xxs;
  }

  &__club-select {
    @media (min-width: $breakpoint) {
      width: 305px;
    }
  }

  &__single-club-info-wrapper {
    margin-top: spacing.$s;
    margin-bottom: spacing.$m;
    color: lightmode.$on-primary;
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
  }

  &__single-club-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__form {
    display: flex;
  }

  &__filters {
    background-color: lightmode.$clean;
    padding: spacing.$xl 0 spacing.$l;
    z-index: 5;
  }

  &__member-list-helpers {
    margin-top: spacing.$l;
  }

  &__info {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: flex-start;
    gap: spacing.$xl;
    margin-top: spacing.$m;
  }

  &__sort {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: spacing.$m;
  }

  &__select-all {
    margin-left: spacing.$l + 1; //NOTE: Set to perfectly align with the checkboxes below.
  }

  &__members {
    display: flex;
    align-items: flex-start;
    gap: spacing.$l;
    margin-top: spacing.$m;
    margin-bottom: spacing.$xl;
    position: relative;
  }

  &__spinner {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(white, 0.7);
  }

  &__member-list {
    color: lightmode.$on-background-primary;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
  }

  &__assign-wrapper {
    position: fixed;
    bottom: 0;
    background-color: lightmode.$clean;
    border-top: 1px solid lightmode.$ui-border;
    text-align: center;
    width: 100%;
    padding: spacing.$m 0;
    z-index: 10;
  }

  &__pagination {
    margin: spacing.$xl 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: spacing.$s spacing.$xxl;
    flex-wrap: wrap;
    padding: 0 spacing.$l;
  }

  &__no-members {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: spacing.$xl;

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: spacing.$xs;
    }
  }
}

.form-content__modal--open {
  z-index: 500; //NOTE: This is a hack until ui-lib is updated
}
